export enum Endpoints {
  USER_LOGIN = '/login?email={{0}}&password={{1}}',
  USER_REGISTER = '/register?company_name={{0}}&phone={{1}}&agreement={{2}}&email={{3}}&password={{4}}&password_confirmation={{5}}',
  GET_USER = '/user',
  PUT_USER = '/user',
  PUT_SETTINGS = '/settings',

  POST_PAYMENT = '/payment/form',
  POST_CALLBACK = '/phone-call',

  GET_ACCRUALS = '/accruals',
  GET_ACCRUALS_PDF = '/accruals/{{0}}/pdf',
  GET_DISTINCT_RECORDS = '/distinct-records',
  DOWNLOAD_1C_REESTR = '/download-1c-reestr',
  ARCHIVE_ACCRUAL = '/accruals/archive-toggle/',

  // accounts
  GET_ACCOUNTS = '/accounts',
  CREATE_ACCOUNT = '/accounts',
  PUT_ACCOUNT = '/accounts/{{0}}',
  SHOW_ACCOUNT = '/accounts/{{0}}',
  DELETE_ACCOUNT = '/accounts/{{0}}',

  // api-token
  GET_API = '/tokens',
  CREATE_API = '/tokens/create',
  DELETE_API = '/tokens/delete/',
  GET_STAT = '/statistic',

  // requisites
  GET_REQUISITES = '/documents',
  SHOW_REQUISITES = '/documents/{{0}}',
  CREATE_REQUISITES = '/documents',
  PUT_REQUISITES = '/documents/{{0}}',
  DELETE_REQUISITES = '/documents/{{0}}',

  // rent
  PARKS = '/autoparks',
  BODY_PARKS = '/autoparks/',

  GET_FULL_CHECK_BATCH = '/full-check/{{0}}',
  POST_DISPATCH_ALL = '/documents/dispatchAll',
  POST_DISPATCH_DOCUMENT = '/documents/dispatch/{{0}}',

  UPLOAD_REESTR = '/upload-reestr',

  GET_OFERTA = '/oferta',
  GET_TARIFS = '/payment/available',

  // dadata
  GET_SUGGESTONS_BANK = '/suggestions/bank?query={{0}}',
  GET_SUGGESTONS_COMPANY = '/suggestions/company?query={{0}}'
}

export function urlBuilder (urlExpression: string, ...params: string[]) {
  return urlExpression.replace(/\{\{(\d+)\}\}/g, (_, p1) => {
    return params[+p1]
  })
}
