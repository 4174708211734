
import { Component, Vue } from 'nuxt-property-decorator'
import AppHeader from '~/components/common/AppHeader/AppHeader.vue'
import SystemNotificationsProvider from '@/components/SystemNotifications/SystemNotificationsProvider.vue'

@Component({
  components: {
    SystemNotificationsProvider,
    AppHeader
  },
  middleware: ['auth']
})
export default class extends Vue {
}
